.card-container {
  height: 62px;
  overflow: hidden;
}
.q-card {
  height: 52px;
  outline: 0.5px solid #e9e9e9;
  display: inline-block;
  width: calc(100% - 15px);
}
.col {
  vertical-align: bottom;
}
.item-card {
  background: #fff;
  font-size: 15px;
  line-height: 18px;
}
.hover .item-card {
  background: #e1e1e1;
  cursor: pointer;
}
/*# sourceMappingURL=src/pages/bookings/backoffice-bookings/booking-card.css.map */