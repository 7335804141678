.sort-icon {
  position: absolute;
  top: 8px;
  font-size: 25px;
}
.dates {
  margin-top: -17px;
}
.btn-wrapper {
  margin-top: 12px;
}
.bookings {
  width: 100%;
}
/*# sourceMappingURL=src/pages/bookings/backoffice-bookings/index.css.map */